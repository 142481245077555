/* External dependencies */
import { navigate } from 'gatsby-plugin-react-intl';
import React, { useEffect } from 'react';

/* Local dependencies */
import Layout from '../components/layout';

export default function Home() {
  /*
   * Below solution is for now
   * TODO: navigate to `/vehicles` when client is logged in and delete this file
   */

  return (
    <Layout>
      <></>
    </Layout>
  );
}
